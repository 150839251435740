<template>
    <b-card :title="$t('dashboard')">
        {{ $t('text_welcome') }}
    </b-card>
</template>
<script>
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('dashboard') }
    }
}
</script>
